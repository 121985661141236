@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap");
$default-font-family: FutureSuperRegular, "Helvetica Neue", sans-serif !important;

$blue-100: #CEDEFF;
$blue-400: #0a5aff;
$orange-100: #FFDDD3;
$orange-600: #AA3A17;
$black-100: #F5F5F5;
$black-200: #EEEEEE;
$black-700: #616161;
$black-900: #212121;
$orange-400: #ff5722;
$green-400: #3dfa52;
$green-300: #77FD79;
$green-200: #A7FFA4;
$green-100: #CCFFC9;
$primary-color: #12426f;
$secondary-color: #3dfa52;
$tertiary-color: #4bbbeb;
$green: #3db4b7;
$gray: #efeeee;
$header-bgcolor: #8598c9;
$subheader-bgcolor: #f2f6f7;
$bg-color: #f5f5f5;
$text-color: #555;
$orange: #f8991d;

@font-face {
  font-family: ABCMonumentGrotesk-Regular;
  src: url('https://sharedassetstorage.blob.core.windows.net/web/FUTR/Fonts/ABCMonumentGrotesk-Regular.woff2') format("woff2");
}

@font-face {
  font-family: ABCMonumentGrotesk-Medium;
  src: url('https://sharedassetstorage.blob.core.windows.net/web/FUTR/Fonts/ABCMonumentGrotesk-Medium.woff2') format("woff2");
}

@font-face {
  font-family: FutureSuperRegular;
  src: url('https://sharedassetstorage.blob.core.windows.net/web/FUTR/Fonts/ABCMonumentGrotesk-Regular.woff2') format("woff2");
  font-weight: 1 999;
  font-display: swap
}


@font-face {
  font-family: FutureSuperHeadings;
  src: url('https://sharedassetstorage.blob.core.windows.net/web/FUTR/Fonts/ABCMonumentGrotesk-Medium.woff2') format("woff2");
  font-weight: 1 999;
  font-display: swap
}

@font-face {
  font-family: "Gilroy-Regular";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyRegular/Gilroy-Regular.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyRegular/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyRegular/font.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBoldItalic/font.woff2")
      format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBoldItalic/Gilroy-ExtraBoldItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBoldItalic/font.woff")
      format("woff");
}
@font-face {
  font-family: "Gilroy-UltraLight";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLight/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLight/Gilroy-UltraLight.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLight/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBoldItalic/font.woff2")
      format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBoldItalic/Gilroy-SemiBoldItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Heavy";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavy/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavy/Gilroy-Heavy.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavy/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Medium";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMedium/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMedium/Gilroy-Medium.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMedium/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Black";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlack/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlack/Gilroy-Black.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlack/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-MediumItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMediumItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMediumItalic/Gilroy-MediumItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyMediumItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-HeavyItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavyItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavyItalic/Gilroy-HeavyItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyHeavyItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Thin";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThin/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThin/Gilroy-Thin.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThin/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-BoldItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBoldItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBoldItalic/Gilroy-BoldItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBoldItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBold/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBold/Gilroy-SemiBold.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroySemiBold/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-UltraLightItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLightItalic/font.woff2")
      format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLightItalic/Gilroy-UltraLightItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLightItalic/font.woff")
      format("woff");
}
@font-face {
  font-family: "Gilroy-RegularItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyRegularItalic/font.woff2")
      format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyUltraLightItalic/Gilroy-UltraLightItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyRegularItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBold/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBold/Gilroy-ExtraBold.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyExtraBold/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-ThinItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThinItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThinItalic/Gilroy-ThinItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyThinItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-BlackItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlackItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlackItalic/Gilroy-BlackItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBlackItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Bold";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBold/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBold/Gilroy-Bold.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyBold/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-LightItalic";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLightItalic/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLightItalic/Gilroy-LightItalic.ttf")
      format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLightItalic/font.woff") format("woff");
}
@font-face {
  font-family: "Gilroy-Light";
  src:
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLight/font.woff2") format("woff2"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLight/Gilroy-Light.ttf") format("ttf"),
    url("https://sharedassetstorage.blob.core.windows.net/web/SMRT/fonts/GilroyLight/font.woff") format("woff");
}
