@import "tenant-variables";

#insurance-update-history-card {

  font-family: ABCMonumentGrotesk-Regular !important;

  .body {
    .grouping {
      .cover-info {
        font-family: ABCMonumentGrotesk-Regular !important;
      }
    }
  }
}

.futr-theme {
  body {
    font-family: $default-font-family !important;
    background-color: #f0eded !important;
    color: $text-color !important;

    .main-container {
      h4 {
        font-family: Calibri !important;
        font-size: 16px !important;
      }

      h1 {
        &.bold {
          font-weight: 600;
        }
      }
    }
  }

  .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
    border-left: none !important;
    border-right: none !important;
  }

  .mat-button-toggle-group {
    border-radius: 12px !important;

    .mat-button-toggle-checked {
      border-radius: 12px !important;
      border-left: none !important;
      border-right: none !important;
    }
  }

  #access-your-super-form {
    app-button {
      mat-icon {
        display: none;
      }
    }
  }

  .navbar-filter {
    .toggle {
      display: none;
    }

    .badge {
      &.badge-primary {
        background-color: $green-100 !important;
        border-radius: 24px;
        padding-right: 16px !important;

        .badge-light {
          border-radius: 12px;
          padding: 4px;
        }
      }
    }
  }

  table {
    &.table {
      thead {
        font-family: ABCMonumentGrotesk-Regular;
        background-color: #f5f5f5;
        font-size: 16px;
        color: $black-700;
      }

      td {
        font-family: ABCMonumentGrotesk-Regular;
        background-color: white;
      }
    }
  }

  p {
    color: $text-color !important;
  }

  #investment-simple-view-container {
    app-button {
      .mat-icon {
        display: none;
      }
    }

    .simple-view-description {
      color: $black-700;
      font-family: ABCMonumentGrotesk-Regular;
    }
  }

  #account-underlying-investment-summary-view-container {
    .assets {
      background-color: white;
      box-shadow: none;

      .asset-type {
        display: none;
      }

      .circle {
        width: 24px;
      }

      .table {
        tr {
          border-top: 1px solid #f5f5f5;

          td {
            padding: 6px;
          }
        }
      }
    }

    app-readonly {
      .title {}

      .app-copyable {
        font-size: 20px;
      }
    }

    .bg-light {
      background-color: $green-100 !important;
      box-shadow: none;

      a {
        color: #212121 !important;
      }

      .badge-primary {
        background-color: white !important;
        padding: 12px;
        font-size: 13px;
      }
    }

    .investment-option {
      &.selected {
        background-color: $primary-color;
      }

      .mat-progress-bar-fill::after {
        background-color: $orange-400;
      }
    }
  }

  .small-secondary-btn {
    &.btn-primary {
      background-color: $green-100;
      border-color: $green-300 !important;
    }
  }

  #recent-employment-details-container {
    .card {
      background-color: white;
      box-shadow: none;
      border: none;

      #app-readonly-container {
        background-color: #f5f5f5 !important;
      }
    }

    .employer-superannuation-plan-url {
      color: #12426f !important;
    }

    .first-row {
      .employer-name {
        font-family: Gilroy-Medium !important;
      }
    }

    .carousel-inner {
      h6 {
        font-family: ABCMonumentGrotesk-Regular !important;
        font-weight: 400;
        font-size: 24px;
      }
    }
  }

  #beneficiaries-view-container {
    .card {
      .beneficiaryinfo {
        h5 {
          color: $primary-color !important;
        }
      }
    }
  }

  app-no-items {
    .alert {
      &.alert-primary {
        border-radius: 12px !important;
      }
    }
  }

  #investment-view-container {
    .btn {
      .mat-icon {
        display: none;
      }
    }

    table {
      td {
        font-size: 20px;
        color: #212121;

        @media screen and (max-width: 992px) {
          font-size: 13px;
        }
      }
    }

    .option1 {
      background-color: #adc8ff !important;

      .ic-container {
        background-color: #84acff !important;
        border-right: 1px solid #84acff;
      }

      .ic {
        color: #2e2e2e !important;
      }

      .arrow {
        color: #84acff !important;
        margin-right: 4px;
      }

      .description {
        color: #2e2e2e;
        font-family: "Gilroy-Medium" !important;
      }
    }
  }

  #login-container {
    .left-side {
      width: 90% !important;
    }

    .right-side {
      width: 30% !important;
    }
  }

  .custom-radio {
    &.gender {
      .mat-error {
        display: none;
      }
    }
  }

  #not-loggedin {
    .card-subtitle {
      p {
        font-size: 12px;
        margin-top: 16px;
      }
    }
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
  .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
    background-color: $secondary-color;
  }

  .mat-menu-item {
    color: $text-color !important;
  }

  .table {
    color: $text-color !important;
  }

  .mat-drawer-container {
    color: $text-color !important;
  }

  .mat-button-toggle {
    &.mat-button-toggle-checked {
      background-color: $black-900;
      color: $green-300;
    }
  }

  .mat-error {
    color: $text-color !important;
    font-weight: 600;
    font-size: 9px;
    margin-left: 0;
  }

  .page {}

  #recentEmploymentCarouselIndicators {
    .carousel-inner {
      .mat-icon {
        color: gray;
      }
    }
  }

  .mat-drawer-content {
    background-color: $bg-color !important;
  }

  .navbar-global-wrapper {
    background-color: #d1def1 !important;
  }

  .scroll-to-top {
    .btn {
      .mat-icon {
        display: inline;
      }
    }
  }

  .list-group-item.active {
    background-color: $green !important;
    border-color: darken($green, 10%) !important;
  }

  .badge-primary {
    background-color: #3dfa52 !important;
    border-color: $green !important;
    color: #212121 !important;
  }

  .badge {
    &.badge-primary {
      background-color: #3dfa52 !important;
      color: #212121 !important;
    }
  }

  a {
    &.btn {
      display: flex;
      align-items: center;
    }
  }

  .btn-outline-primary {
    background-color: transparent !important;
    border-color: $secondary-color !important;
    color: $secondary-color !important;
    font-weight: 500;
    border-radius: 0;
    text-decoration: none;
  }

  .btn-primary {
    background-color: $green-400;
    border-color: $green-400 !important;
    color: #212121 !important;
    font-weight: 500;
    border: 2px solid $green-400;

    .mat-icon {
      color: #2e2e2e !important;
    }

    &.active {}

    &:hover {
      background-color: $green-200 !important;
      color: #2e2e2e !important;
    }

    &:focus,
    &.focus {
      box-shadow: none;
    }
  }

  .btn {
    direction: ltr;
    letter-spacing: 1px;
    transition: transform 0.2s;

    /* Animation */
    @media screen and (max-width: 992px) {
      span {
        margin: auto;
      }
    }

    &:hover {
      transition: background-color 0.5s ease;
    }

    &:focus,
    &.focus {
      box-shadow: none;
    }
  }

  .btn-secondary {
    background-color: $secondary-color;
    border-color: darken($secondary-color, 10%) !important;
    color: white !important;
    border-radius: 0;

    &:hover {
      background-color: white;
      color: $secondary-color !important;
      border-color: white !important;
    }
  }

  .card.c-shadow {
    box-shadow: 3px 3px 9px #2e2e2e;
  }

  #member-account-dropdown-container {
    @media screen and (max-width: 992px) {
      #dropdownMenuButton {
        margin: auto;
      }
    }

    .btn {
      font-family: ABCMonumentGrotesk-Medium !important;
      border: none !important;
      background-color: #212121;
      color: #fff !important;

      span {
        margin-right: 0;
      }
    }

    .mat-icon {
      display: inline;
    }

    .selected-info {
      >div {
        width: auto;
      }

      &.account {
        display: none !important;
      }
    }
  }

  .btn {
    font-family: FutureSuperHeadings !important;
    text-transform: none;
    justify-content: start !important;
    font-size: 14px;
    min-height: 36px;
    padding: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    font-weight: unset;
    position: relative;
    border-radius: 30px;

    &.menu-item {
      font-weight: 100;
    }

    span {
      flex-direction: row-reverse;
    }

    .mat-icon {
      margin-left: 8px;
      margin-right: 0 !important;

      @media screen and (max-width: 992px) {
        margin-left: 0px;
      }
    }

    &.btn-danger {
      background-color: #ffddd3;
      border: 1px solid #ff8f6c;
      color: #ff8f6c;

      .mat-icon {
        color: #ff8f6c;
      }
    }
  }

  #app-investment-edit-wrapper {
    .components-actions {
      .mat-icon {
        display: none;
      }
    }
  }

  #personal-details-container {
    .btn {
      .mat-icon {
        display: none;
      }
    }
  }

  #app-consolidate-super-wrapper {
    .content {
      overflow-x: hidden;
    }

    .components-actions {
      .mat-icon {
        display: none;
      }
    }
  }

  #transaction-listing-container {}

  #historical-graph-view-container {
    .chart_infobox {
      background-color: $black-100;
    }

    app-button {
      .mat-icon {
        display: none !important;
      }
    }

    .total-amount {
      text-align: left !important;

      h2 {
        font-weight: 600;
        font-size: 33px !important;
        color: $black-900 !important;
      }

      &.displayGraph {
        h1 {
          text-align: initial;
        }

        h3 {
          color: $black-700 !important;
          font-family: ABCMonumentGrotesk-Regular !important;
          font-weight: 400;
        }

        .icon-wrapper {
          display: none !important;
          background-color: transparent !important;

          .mat-icon {
            color: #212121;
            width: 45px !important;
            height: 45px !important;
            font-size: 45px !important;
          }
        }
      }
    }

    .btn-group>.btn:not(:last-child):not(.dropdown-toggle),
    .btn-group>.btn-group:not(:last-child)>.btn {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    .btn-group>.btn:not(:first-child),
    .btn-group>.btn-group:not(:first-child)>.btn {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    .btn-group {
      .btn {
        min-width: 70px !important;
        margin: 2px;
        min-width: auto;
        border-color: #f5f5f5 !important;
        background-color: #f5f5f5;

        &.active {
          background-color: $black-900 !important;
          color: $green-300 !important;
        }

        &:hover {
          background-color: $black-200;
        }
      }

      @media screen and (max-width: 992px) {
        top: 15px !important;
      }
    }

    .links {
      direction: rtl;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }

      app-button {
        min-width: auto;
        margin-right: 16px;
        margin-bottom: 8px;
      }
    }
  }

  #calculator-container {
    .page-content-actions {
      justify-content: end;
    }

    .calculator {

      p,
      li {
        color: $text-color;
      }
    }
  }

  .btn-light {
    color: #212121 !important;
    font-family: FutureSuperHeadings !important;

    border: 2px solid #424242;
    background-color: white;

    &:hover {
      border: 2px solid $green-300;
      background-color: $green-300 !important;
    }

    .mat-icon {
      color: $secondary-color !important;
    }

    &.custom {
      background-color: darken($color: $primary-color, $amount: 5%);
      border: 1px solid darken($color: $primary-color, $amount: 10%);
    }

    &.back {
      justify-content: end !important;

      span {
        margin-left: 0;
        margin-right: 0;
      }

      .mat-icon {
        position: absolute;
        left: 16px;
        margin-left: 0 !important;
      }
    }
  }

  #consolidate-super-container {
    .description {
      font-family: ABCMonumentGrotesk-Regular !important;
    }

    .header {
      h3 {
        font-family: Calibri, serif !important;
        font-size: 16px !important;
      }
    }

    .card {
      &.requested {
        background-color: $black-200 !important;

        h6 {
          color: $black-900 !important;
        }

        label {
          color: $black-700 !important;
        }

        .icon {
          color: $blue-400;

          .mat-icon {
            color: $blue-400 !important;
          }
        }
      }

      &.pending {
        background-color: $black-200 !important;

        h6 {
          color: $black-900 !important;
        }

        label {
          color: $black-700 !important;
        }

        .icon {
          color: $orange-400;

          .mat-icon {
            color: $orange-400 !important;
          }
        }
      }
    }
  }

  #member-view-overview {
    @media screen and (max-width: 992px) {
      margin-right: 0px !important;
    }

    .content {
      background-color: white;
    }

    .page-component {
      border-radius: 16px;
      overflow: hidden;
    }

    #member-view-overview-container {
      .member-view-content {
        box-shadow: none;
        margin-top: 20px;
      }

      #app-readonly-container {
        border: none;

        .title {
          color: $black-700 !important;
        }

        .app-copyable {
          color: #212121 !important;

          .mat-icon {
            color: $black-700;
          }
        }
      }

      .member-name {
        @media only screen and (max-width: 992px) {
          font-size: 30px !important;
          line-height: 30px;
        }
      }

      .header {
        background-color: white !important;
        color: #212121;
        font-size: 48px;
        font-family: ABCMonumentGrotesk-Regular !important;
        border-bottom: none;
        white-space: nowrap;
        margin-bottom: 0 !important;

        .hey-header {
          font-family: ABCMonumentGrotesk-Regular !important;
          font-size: 56px !important;
          line-height: 56px;
          color: #212121 !important;

          @media screen and (max-width: 992px) {
            margin-bottom: 0;
            font-size: 30px !important;
            line-height: 30px;
          }
        }



        .post-greeting {
          font-size: 48px;

          @media screen and (max-width: 992px) {
            font-size: 24px;
          }
        }
      }

      img {
        display: bloco;
        height: 122px !important;
        margin-top: -86px !important;

        @media screen and (max-width: 992px) {
          height: 151px !important;
          margin-top: -121px !important;
          margin-right: -30px;
        }
      }
    }
  }

  #notification-dropdown-container {
    .btn-link {
      .mat-icon {
        color: $secondary-color !important;
      }
    }
  }

  #user-top-dropdown {
    .btn-primary {
      color: #212121;

      @media screen and (max-width: 992px) {
        min-width: 60px;
      }

      &:hover {
        background-color: #212121 !important;
        color: white !important;

        .mat-icon {
          color: white !important;
        }
      }
    }

    .mat-icon {
      display: inline;
    }

    .dropdown-toggle {
      color: #212121 !important;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      background-color: white;

      &::before {
        border-bottom: 10px solid white;
      }

      a {
        color: #212121 !important;
        text-transform: none;
        font-size: 12px;
        padding: 8px 16px !important;
        font-family: Gilroy-SemiBold;

        &:hover {
          background-color: transparent !important;
        }

        .mat-icon {
          color: #00be00;
          font-size: 17px;
          height: 17px;
          width: 17px;
        }
      }

      li {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  .page-header {
    .card {
      background-color: white;
      display: none;

      .mat-icon {
        color: $secondary-color;
      }
    }

    .page-title {
      font-family: ABCMonumentGrotesk-Regular !important;
      color: #212121;
      font-size: 22px;
      padding-bottom: 0;
      text-transform: none;
    }

    .page-sub-title {
      color: $secondary-color;
    }
  }

  .mat-form-field {
    &.mat-form-field-invalid {
      .mat-form-field-flex {}
    }
  }

  mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: white !important;
    border: 1px solid #8996fb;
    border-radius: 4px !important;
  }

  .mat-form-field-underline {
    display: none;
  }

  a {
    color: $text-color !important;
    font-weight: 500;
    text-decoration: underline;
    font-size: 12px;

    &.mat-tooltip-trigger,
    &.dropdown-item,
    &.no-decoration {
      text-decoration: none;
    }
  }

  .description {
    font-family: ABCMonumentGrotesk-Regular !important;
    color: $text-color;
    font-size: 13px;
  }

  ul {
    li {
      color: $text-color;
    }
  }

  .mat-expansion-panel-header-title {
    color: $secondary-color !important;
  }

  .list-group-item-action {
    color: $text-color !important;
  }

  .mat-checkbox-label {
    color: $text-color !important;
  }

  #app-readonly-container {
    .value {
      color: $text-color !important;
      font-family: ABCMonumentGrotesk-Regular;
    }

    .title {
      font-family: ABCMonumentGrotesk-Regular;
      color: $text-color !important;

      font-size: 12px !important;

      .mat-icon {
        color: $black-900 !important;
      }
    }

    .app-copyable {
      font-size: 18px !important;
      font-weight: 400;
    }
  }

  h1 {
    font-family: FutureSuperHeadings, serif !important;
    font-size: 27px;
  }

  h2,
  h3,
  h4,
  h5 {
    color: $text-color !important;
    font-family: FutureSuperHeadings !important;

    &.subheader {
      font-family: $default-font-family !important;
      font-weight: 700;
    }
  }

  a {
    &.btn {
      &.btn-link {
        display: grid;
      }
    }
  }

  .page-component {
    margin-top: 0;
    padding-top: 30px;

    @media screen and (max-width: 992px) {
      padding-top: 15px;
    }

    .page-content-actions {
      border-top: none !important;
      justify-content: end;
    }

    .content {
      border-radius: 16px !important;
      overflow: inherit;
      border: none !important;
      box-shadow: none;
      padding: 32px !important;

      .header {
        .mat-icon {
          display: none !important;
        }
      }
    }

    a {
      color: #00be00 !important;
      text-decoration: underline;

      &.btn-primary {
        color: white !important;
      }
    }

    .header {
      margin-top: 0px !important;
      margin-bottom: 20px !important;

      &.danger {
        .title {}
      }

      .mat-icon {
        color: #00d30f;
        font-size: 20px;
        height: 20px !important;
      }

      .card-subtitle {
        color: $secondary-color;
      }

      .title {
        color: #212121 !important;
        font-family: ABCMonumentGrotesk-Medium, serif !important;
        font-size: 14px;
        text-transform: uppercase;
        padding-top: 3px;
      }
    }
  }

  :host .historical-graph-component {
    .page-component {
      .total-amount {
        h2 {
          color: $tertiary-color !important;
        }
      }

      .title,
      .mat-icon {}

      .content {}
    }
  }

  .mat-form-field.mat-focused .mat-form-field-label .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $secondary-color !important;
  }

  #rollin-container {
    .btn {
      .mat-icon {
        display: none;
      }
    }

    .attach_money {
      .mat-form-field-prefix {
        display: block;
      }
    }

    #btnGoToDashboard {
      display: none;
    }
  }

  .mat-form-field {
    .mat-form-field-prefix {
      display: block;

      .mat-icon {
        color: gray !important;
      }
    }
  }

  .mat-form-field-label,
  .mat-radio-group {
    color: #575757 !important;
  }

  #insurance-questionnaire-start-container {
    form {
      position: initial;
    }

    .option-2 {
      display: none;
    }

    .option-1 {
      display: block;
    }

    .top-progress {
      padding-top: 8px !important;
      padding-bottom: 8px !important;

      .progressbar {
        li.active {
          &::before {
            border-color: darken($color: #3dfa52, $amount: 20%) !important;
            background: #3dfa52 !important;
          }

          &::after {
            background: #3dfa52 !important;
          }
        }
      }

      .progress {
        .progress-bar {
          background-color: $green !important;
        }
      }
    }

    .components-actions {
      app-button {
        flex: none;
        max-width: initial;
      }
    }
  }

  .mat-menu-content {
    background-color: transparent !important;
  }

  #app-readonly-container {
    background: white !important;
    margin-bottom: 5px;
    border-left: none !important;
    padding: 5px 5px 0px 8px !important;
    border: none;
    border-radius: 8px;
  }

  .header {
    &.danger {
      color: #f47b63 !important;
    }
  }

  .components-actions {
    direction: rtl !important;
    border-top: none !important;
  }

  #switch-investments-container {
    .card {
      .header {
        background-color: transparent !important;
        border-bottom: none !important;
      }

      .components-actions {
        border-top: none !important;
      }

      &.option-0 {
        background-color: #f6cdb3 !important;
      }

      &.option-1 {
        background-color: #8598c9 !important;
      }

      &.option-2 {
        background-color: #3db4b7 !important;
      }
    }
  }

  #unit-prices-view-container {
    .apexcharts-inner {
      defs {
        linearGradient {
          stop:nth-child(1) {
            stop-color: #3db4b7;
          }

          stop:nth-child(2) {
            stop-color: lighten(#3db4b7, 15%);
          }

          stop:nth-child(3) {
            stop-color: lighten(#3db4b7, 50%);
          }
        }
      }
    }

    .apexcharts-series {
      path:nth-child(2) {
        stroke: #3db4b7 !important;
      }
    }
  }

  #account-balance-view-container {
    .apexcharts-series {
      path {
        fill: lighten($tertiary-color, 10%) !important;
      }
    }

    .apexcharts-data-labels {
      text {
        fill: $orange-400 !important;
      }
    }
  }

  .toast-container {
    .toast-success {
      background-color: #f5ffff !important;
      border: 1px solid #50c9ce;
      color: $secondary-color !important;
      background-image: url("https://vervassets.blob.core.windows.net/web/toaster-success.png") !important;
      font-weight: 500;
      padding: 32px;
    }

    .toast-error {
      background-color: #fba689 !important;
      color: $secondary-color !important;
      background-image: url("https://vervassets.blob.core.windows.net/web/toaster-error.png") !important;
      font-family: "Calibri" !important;
      border: 1px solid #f16d1f;
      font-weight: 500;
      padding: 32px;
    }

    .toast-message {
      margin-left: 16px;
    }
  }

  #limited-service-banner {
    .limited-service-panel {

      .mat-expansion-panel-header-description,
      .mat-expansion-indicator::after {
        color: white;
      }

      .mat-expansion-panel {
        font-size: 14px !important;
        background-color: #000e2b !important;
        border-color: #030d2b !important;
        border-radius: 12px 0px;
        margin-right: 15px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

        .mat-expansion-panel-header-title {
          display: flex;
          align-items: center;
        }

        .mat-expansion-panel-header-title,
        .mat-expansion-panel-body p {
          color: white !important;
          white-space: break-spaces;
        }

        &:not(.mat-expanded):not([aria-disabled="true"]) .mat-expansion-panel-header:hover {
          background-color: #000e2b !important;
        }

        li {
          white-space: break-spaces;
        }

        a {
          color: $orange-400 !important;
        }
      }
    }
  }

  #take-me-with-you-container {
    .btn {
      .mat-icon {
        display: none;
      }
    }
  }

  .alert {
    font-family: ABCMonumentGrotesk-Medium;
    font-size: 16px;

    &.alert-danger {
      border-color: darken($color: lavenderblush, $amount: 10) !important;
      background-color: lavenderblush !important;
      color: $text-color !important;
      border-radius: 12px 0;
    }

    &.alert-warning {
      border-color: #50c9ce !important;
      background-color: #f5ffff !important;
      color: $text-color !important;
      border-radius: 12px 0;
    }

    &.alert-info {
      border-color: $blue-100 !important;
      background-color: $blue-100 !important;
      color: $black-900 !important;
      border-radius: 12px;
    }

    &.alert-primary {
      border-color: #f5f5f5 !important;
      background-color: #f5f5f5 !important;
      color: $text-color !important;
      border-radius: 12px 0;
    }

    &.alert-success {
      border-color: $blue-100 !important;
      background-color: $blue-100 !important;
      color: $black-900 !important;
    }

    &.limited-service-alert {
      font-size: 14px !important;
      color: white !important;
      background-color: #000e2b !important;
      border-color: #030d2b !important;
      border-radius: 12px 0px;
      margin-right: 15px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

      p {
        white-space: break-spaces;
      }

      a {
        color: $orange-400 !important;
      }

      .toggle-button {
        color: $orange-400 !important;
        text-decoration: none;
        font-family: Calibri;
        font-weight: bold;
        font-size: 15px;
      }

      .banner-title {
        font-size: 16px !important;
        font-weight: 500;
      }

      .mat-icon {
        color: white !important;
      }

      ul {
        margin-bottom: 0px !important;

        li {
          color: white !important;

          p {
            margin-bottom: 0px !important;
            color: white !important;
          }
        }
      }
    }
  }

  #personal-contributions-edit-container {
    .dd {
      border: 2px solid $primary-color !important;
    }

    .step {
      background-color: $gray;
    }

    .attach_money {
      .mat-form-field-prefix {
        display: block;
      }
    }
  }

  #consolidate-your-syper-container {
    .transfers {
      .alert {
        &.alert-warning {
          .icon {
            background-color: #fff6ee !important;
            border-color: #f16d1f !important;
          }

          h6 {
            color: $primary-color !important;
          }
        }
      }
    }
  }

  app-scroll-up {
    .btn {
      min-width: 50px;

      .mat-icon {
        position: initial;
        margin-left: 0 !important;
      }
    }
  }

  #contributions-limits-view-container {
    #gauge {
      .gauge--data {
        background-color: #3db4b7;
      }

      .gauge--background {
        background-color: #e0f1f3;
      }
    }

    .in,
    .out {
      color: #3db4b7;
    }

    .action {
      direction: rtl;
    }

    app-button {
      min-width: 200px;
    }
  }

  .apexcharts-area {
    stroke: darken($tertiary-color, 1%);
  }

  #apexchartshistoricalchart {
    [id^="SvgjsLinearGradient"] {
      stop:first-child {
        stop-color: $tertiary-color !important;
      }

      stop:nth-child(2) {
        stop-color: lighten($tertiary-color, 30%) !important;
      }

      stop:nth-child(3) {
        stop-color: lighten($tertiary-color, 50%) !important;
      }
    }
  }

  app-cash-transactions {
    .btn {
      &.btn-link {
        min-width: 50px;
      }
    }
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: lightgray !important;
    border-radius: 8px;
    background: white;
  }

  #member-view-container {
    .app-slide-toggle {
      background-color: transparent;
      border-bottom: none;
      padding-left: 0;
    }

    .provider-item {
      &:hover {
        border-left-color: #50c9ce !important;

        .mat-icon {
          color: #50c9ce !important;
        }
      }
    }
  }

  app-entry-address-accurity {
    h5 {
      font-family: "Gilroy-Medium" !important;
      font-size: 12px !important;
    }

    mat-radio-group {}
  }

  .mat-radio-label-content,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label,
  .mat-form-field-label {
    mat-label {
      color: #575757 !important;
      font-weight: 600;
      font-size: 14px;
      padding-right: 4px;
    }

    &.mat-form-field-empty {
      mat-label {
        color: #575757 !important;
        font-weight: 400;
      }
    }
  }

  .mat-form-field {
    &.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: #f16d1f !important;
      }
    }

    .mat-form-field-outline {
      background-color: white;
    }

    .mat-select.mat-select-invalid .mat-select-arrow {
      color: $secondary-color !important;
    }

    .mat-select-value {
      color: $text-color !important;
    }
  }

  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $primary-color;
  }

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $primary-color;
  }

  .mat-checkbox.mat-accent.mat-checkbox-checked .mat-checkbox-background {
    background-color: $primary-color;
  }

  .mat-icon {
    color: $black-900;
  }

  #access-your-super-start {
    .btn {
      .mat-icon {
        display: none;
      }
    }

    .btn-group {
      .btn {
        min-width: auto;
        border-color: #f5f5f5 !important;

        &.active {
          background-color: $orange-400 !important;
          color: white !important;
        }

        &:hover {
          background-color: $orange-400 !important;
          color: white !important;
        }
      }

      @media screen and (max-width: 992px) {
        top: 15px !important;
      }
    }
  }

  :host ::ng-deep .apx-chart {
    div:first-child {}
  }
}

.mat-menu-panel {
  .list-unstyled {
    margin-left: -12px;
    background-color: transparent;
  }

  a {
    line-height: 2.4;

    .mat-icon {
      margin-right: 12px;
      color: $secondary-color;
    }
  }
}

.mat-step-header {
  .mat-step-icon-state-number.mat-step-icon-selected {
    background-color: $green-400 !important;
    color: $black-900 !important;
    font-weight: 800;
  }

  .mat-step-icon-state-number {
    background-color: $black-900 !important;
  }

  .mat-step-icon-state-edit {
    background-color: $green-400 !important;
    font-weight: bold;
  }

  .mat-icon {
    color: $black-900 !important;
  }
}

.mat-standard-chip {
  color: $green-400 !important;
  border: 1px solid #e0dfdf;
  background-color: white !important;
}

.mat-chip-selected {
  color: $black-900 !important;
  background-color: $green-400 !important;
}

.mat-chip.mat-standard-chip::after {
  background: unset !important;
}

.mat-mdc-menu-content {
  background-color: white;

  .mat-mdc-menu-item {
    &:hover {
      background-color: $green-100 !important;
      border-radius: 24px;
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch.mdc-switch--selected:enabled {
    .mdc-switch__icon {
      fill: $green-300 !important;
    }

    .mdc-switch__handle::after {
      background: #212121 !important;
    }
  }

  .mdc-switch:enabled {
    .mdc-switch__track::after {
      background-color: white !important;
      border: 1px solid lightgray;
    }
  }
}

.mat-mdc-radio-button {

  .mdc-radio__outer-circle,
  .mdc-radio__inner-circle {
    border-color: #212121 !important;
  }
}

.mat-calendar-body-cell {
  .mat-calendar-body-cell-content {
    &:hover {
      background-color: $green-100;
    }
  }
}

.mat-horizontal-stepper-wrapper {
  .mat-stepper-horizontal-line {
    margin: 0;
    margin-right: 8px;
  }
}

.mat-step-header {
  &.mat-horizontal-stepper-header {
    height: auto;
    padding: 0;
    border-radius: 16px;
  }
}

#app-greenid {
  .mat-mdc-progress-bar {
    position: absolute;
    bottom: 0;
  }

  .item {
    &.not-verified {
      background-color: #f5f5f5 !important;
      border-color: $orange-600 !important;
      color: $orange-600 !important;

      .greenid-icon-wrapper {
        border-radius: 16px;
        background-color: $orange-100;
        display: flex;
        align-items: center;
        padding: 4px;
        margin-right: 8px;

        .mat-icon {
          margin: 0;
          margin-right: 0 !important;
          font-size: 20px;
          width: 20px;
          height: 20px;
          color: $orange-600 !important;
          margin-left: 8px;
          margin-right: 8px !important;
        }
      }
    }

    &.verified {
      background-color: $black-900 !important;
      border-color: $orange-600 !important;
      color: $green-300 !important;

      .greenid-icon-wrapper {
        border-radius: 16px;
        background-color: $green-300;
        display: flex;
        align-items: center;
        padding: 4px;
        margin-right: 8px;

        .mat-icon {
          margin: 0;
          margin-right: 0 !important;
          font-size: 20px;
          width: 20px;
          height: 20px;
          margin-left: 8px;
          margin-right: 8px !important;
          color: $black-700 !important;
        }
      }
    }
  }
}

.mat-icon {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.mat-mdc-option {
  &.mdc-list-item {
    &:hover {
      background-color: $green-100;
      border-radius: 16px;
    }
  }
}

.entry-file-main {
  .entry-file-icon-wrapper {
    padding: 16px;
    border-radius: 32px;

    &.valid {
      background-color: $green-300;
    }

    &.invalid {
      background-color: $black-200;

      .mat-icon {
        transform: rotate(90deg);
      }
    }

    .mat-icon {
      height: 30px !important;
      width: 30px !important;
      font-size: 30px !important;
      color: $black-700 !important;
    }

    .file-valid {
      &.mat-icon {
        height: 30px !important;
        width: 30px !important;
        font-size: 30px !important;
        color: $black-700 !important;
      }
    }
  }
}

.mat-mdc-checkbox {
  .mdc-form-field {
    label {
      font-family: ABCMonumentGrotesk-Regular;
      font-size: 12px;
    }
  }
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled~.mdc-checkbox__background {
  border-color: darkgray !important;
  background-color: transparent !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  height: 12px !important;
  width: 12px !important;
  padding-left: 2px !important;
  padding-top: 2px !important;
}

// ::ng-deep .futr-theme {
#chat-view-container {
  bottom: 80px !important;

  button.mat-primary {
    background-color: $green-300;
  }

  .timeline-badge {
    &.end {
      color: $black-700 !important;
    }
  }

  .timeline-title {
    font-size: 12px !important;

    .mat-icon {
      color: darkseagreen;
    }
  }
}

// }