@import "variables";
@import "styles-themes";
html,
body {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: $default-font-family;
  background-color: $background-color;
  overflow-x: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.mat-drawer-container {
  background-color: transparent;
}
.description {
  color: gray;
}
.mat-dialog-container {
  background: white;
}

.danger {
  color: tomato !important;
  .mat-icon,
  .title {
    color: inherit !important;
  }
}
.dropdown {
  ul.dropdown-menu {
    border-radius: 4px;
    box-shadow: none;
    margin-top: 20px;
    &:before {
      content: "";
      border-bottom: 10px solid #fff;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      position: absolute;
      top: -10px;
      right: 16px;
      z-index: 10;
    }
    &:after {
      content: "";
      border-bottom: 12px solid rgba(0, 0, 0, 0.15);
      border-right: 12px solid transparent;
      border-left: 12px solid transparent;
      position: absolute;
      top: -12px;
      right: 14px;
      z-index: 9;
    }

    &.left {
      &:before {
        left: 16px;
        right: inherit;
      }
      &:after {
        right: inherit;
        left: 14px;
      }
    }
  }
}
.dropdown-menu {
  > li {
    > a {
      color: #428bca;
    }
  }
}

.page-header {
  position: relative;
  margin-top: -140px;
  background-color: transparent;
  margin-bottom: 10px;
  .page-title {
    color: white;
    font-weight: 500;
    white-space: initial;
    line-height: 34px;
  }
  .page-sub-title {
    color: lightgray;
    white-space: pre-line;
  }
}
.components-actions {
  display: flex;
  align-items: flex-end;
  direction: rtl;
  margin-top: 15px;
  border-top: 1px solid lightgray;
  padding: 10px;
  padding-bottom: 0;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-left: 20px;

  @media screen and (max-width: 992px) {
    flex-direction: column !important;
    app-button {
      width: 100% !important;
      margin-bottom: 5px;
    }
    .w-50 {
      width: 100% !important;
    }
    .mr-3 {
      margin-right: 0 !important;
    }
  }
}
.page-component {
  margin-top: 30px;

  .page-content-actions {
    border-top: 1px solid lightgray;
    padding-bottom: 0;

    @media screen and (max-width: 992px) {
      flex-direction: column !important;
      app-button {
        margin-bottom: 5px;
      }
      .ml-1 {
        margin-left: 0 !important;
      }
      .mr-1 {
        margin-right: 0 !important;
      }
    }
  }

  .header {
    .toolbarheader-icon {
      width: 20px !important;
      margin-right: 10px;
    }
  }
}

.min-h-100 {
  min-height: 100%;
}

.mat-drawer-content {
  &.full {
    min-height: 100vh;
  }
}
.page {
  position: relative;
  margin-top: -55px;
  background-color: white;
  border: solid 1px lightgray;
  border-top: none;
  padding: 20px;
  border-radius: 5px;
}

.page-title {
  margin-top: 0;
  font-size: 30px;
  color: gray;
  padding: 10px;
  padding-bottom: 10px;
  @media screen and (max-width: 992px) {
    font-size: 25px;
  }
}

.page-sub-title {
  margin-top: -8px !important;
  font-size: 15px;
  color: lighten($color: gray, $amount: 10%);
  padding: 0;
  padding-left: 10px;
}

.section-title {
  margin-top: 0;
  font-size: 25px;
  color: gray;
  padding: 10px;
}

.mat-dialog-title {
  color: gray;
  margin-bottom: 16px !important;
}
.card {
  &.c-shadow {
    box-shadow: 3px 3px 9px #d3d3d3;
  }
  &.bg-color {
    background: #f5f5f5;
  }
  .card-bottom {
    border-top: 2px solid $background-color;
    padding: 15px;
    height: 70px;
    z-index: 10;
  }
  .card-body {
    margin-top: 15px;
    border-top: 2px solid $background-color;
    padding-bottom: 0;
  }
  .card-title {
    font-size: 20px;
    margin-bottom: 5px;
    color: gray;
    padding: 15px;
    padding-bottom: 0;
  }
  .card-subtitle {
    padding: 15px;
    padding-top: 5px;
    padding-bottom: 0;
    font-size: 14px;
    margin-bottom: 0 !important;
    &.icon {
      display: flex;
      align-items: initial;

      .mat-icon {
        font-size: 16px;
        margin-right: 0;
      }
    }
  }
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

.app-button {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #000000;
  }
}

.loading-screen-wrapper {
  z-index: 100000;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  display: block;
}

.loading-screen-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.end-of-page {
  text-align: center;
  color: red;
  .mat-icon {
    margin-top: 40px;
    font-size: 30px;
  }
}

.flex-1 {
  flex: 1 1 0px;
}

.jc-flex-end {
  justify-content: flex-end;
}

.text-fade-content {
  line-height: 20px;
}
.text-fade {
  position: relative;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 100%;
    height: 22px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
  }
}

.table-responsive {
  font-size: 16px;

  td {
    padding: 6px;
  }
}

.click {
  cursor: pointer;
}

.container {
  &.edit {
    margin-top: 40px;
    z-index: 2;
  }
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 1);
}

.cdk-overlay-container {
  z-index: 1010 !important;
}

.toolbarheader-icon {
  padding: 0 !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.toast-bottom-center {
  max-width: 600px !important;
  width: 600px !important;
  min-width: 400px !important;
  .ngx-toastr {
    width: 100% !important;
    white-space: pre;
  }
}

.custom-entry-field {
  padding-top: 8px;
}

.table {
  th,
  td {
    font-weight: 100;
  }
  thead {
    th {
      font-weight: 450;
    }
  }
}
.badge {
  font-weight: 400;
}

.mat-bottom-sheet-container {
  &.warning {
    border-left: 5px solid tomato !important;
    border-radius: 0;
    background: #f5f5f5;
    max-width: 800px;
  }
}

app-toolbar-options {
  .navbar {
    background-color: transparent !important;
  }
}

.table th,
.table td {
  vertical-align: middle;
}

.mat-select-panel {
  background: white;
  border: 1px solid #d3d3d3;
  box-shadow: 1px 1px 3px #d3d3d3;
}

.widgets-row {
  @media screen and (max-width: 992px) {
    flex-direction: column !important;

    .widget {
      align-items: stretch;
      width: 100% !important;
    }
    .w-50 {
      width: 100% !important;
    }
  }
}

.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}

@media screen and (max-width: 992px) {
  .page-content {
    > .d-flex {
      flex-direction: column;

      .w-50 {
        width: 100% !important;
      }
    }
  }
}

.scroll-to-top {
  z-index: 10;
  @media screen and (max-width: 992px) {
    margin-bottom: 45px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}
.mat-radio-group {
  .mat-radio-label-content {
    white-space: pre-line !important;
  }
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none !important;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

.no-text-decoration {
  text-decoration: none !important;
}
.mat-datepicker-content .mat-calendar {
  background: white;
  border: 1px solid #d3d3d3;
}

.entry-file-main {
  .entry-file-btn {
    min-width: 230px !important;
  }
}

.docs {
  .list-group-item {
    border: none;
    margin-bottom: 4px;
  }
}
.btn {
  &.invert-btn {
    .mat-icon {
      left: 16px;
      right: auto;
    }
    span {
      margin-left: 48px;
      margin-right: 0;
    }
  }
}

.mat-tooltip {
  background-color: gray !important;
}

.mat-button-toggle {
  color: gray;
  &.mat-button-toggle-checked {
    color: #2e2e2e;
    border: none;
    button {
      &:focus {
        outline: none !important;
      }
    }
  }
}

.app-button-toggle-content {
  h4 {
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 8px;
  }
}
.mat-calendar-body-cell-content {
  border-radius: 6px !important;
  border-color: lightgray !important;
}
.mat-bottom-sheet-container {
  background-color: white;
  .mobile-menu {
    padding-bottom: 66px;
    display: block;
  }
}

mat-form-field {
  .mat-form-field-suffix {
    display: flex;
    .mat-icon {
      &.invalid {
        color: tomato !important;
      }
    }
  }
}

app-button-toggle {
  .mat-icon {
    &.invalid {
      color: tomato !important;
      font-size: 20px;
      height: 20px;
    }
  }
}

app-checkbox {
  display: flex;
  .mat-icon {
    &.invalid {
      color: tomato !important;
      font-size: 24px;
      height: 24px;
    }
  }
}

.bpay-logo-image {
  background-image: url("/assets/bpay.png");
  background-size: cover;
}
#app-greenid {
  .item {
    zoom: 1.2;
    border-radius: 4px;
  }
}

.access-your-super-drawdown {
  .mat-form-field-infix {
    width: unset !important;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
}

.access-your-super-drawdown.alert {
  width: fit-content;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.mat-autocomplete-panel {
  background: #f5f5f5;
  border: 1px solid lightgray;
  border-top: 0;
}

.mat-mdc-menu-content {
  .mat-mdc-menu-item,
  > div {
    background-color: white;
  }
}
.mat-mdc-menu-panel {
  max-width: 400px !important;
  .mat-mdc-text-field-wrapper {
    height: 56px;

    .mat-mdc-form-field-infix {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
.mat-mdc-form-field {
  .mat-mdc-form-field-icon-suffix {
    display: flex;
  }
  .mat-mdc-form-field-icon-prefix {
    .mat-icon {
      color: gray !important;
    }
  }
}
.mdc-tooltip {
  .mdc-tooltip__surface {
    background: lighten($color: #f1f1f1, $amount: 2%);
    border: 1px solid lightgray;
    font-size: 11px;
  }
}
.mat-mdc-select-panel {
  background-color: white;
}

.mat-icon {
  overflow: initial !important;
}

.w-fit-content {
  width: fit-content;
}

.mat-mdc-checkbox {
  label {
    margin-bottom: 0;
  }
}

.pac-container {
  z-index: 10000 !important;
}

.btn:focus,
.btn.focus {
  box-shadow: none !important;
}

.margin-auto {
  margin: auto;
}

.fit-content{
  width: fit-content;
}
